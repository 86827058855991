import style from '@/Features/NewsComponents/news.module.sass';
import Link from 'next/link';
import { useAppSelector } from '@/Hooks/redux';
import { GeoState } from '@/Redux/Slices/GeoSlice/GeoState';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { HeadingSlice } from '@/Redux/Slices/HeadingSlice/HeadingSlice';
import { useDispatch } from 'react-redux';

import { NewsCardMTButtonsCard } from '@/Features/MT/ButtonsCard';
import { LocalhostingNews } from './components/openComp';
import { getNewsLocal, ClearAll } from '@/Redux/saga/getPostLocalNews';
import { ArticlesMTCounters } from '@/Features/MT/StoryIDCounters';
import { TranslateFunc } from '@/lib/translate';
import { ModalWindowGeo } from '@/Features/ModalWindowGeo/ModalWindowGeo';

export const LocalhostNews = () => {
	// ==================================
	const [isMobile, setIsMobile] = useState(false);
	const WhiteList = useAppSelector(state => state.SourseSlice);
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		// Check on mount
		handleResize();

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// ===============================
	const [isContry, setisContry] = useState(false);
	const { SetcurrentLocalId } = HeadingSlice.actions;

	const { newsViewBollean } = useAppSelector(state => state.NewsViewSlice);
	const { CurrentFontSize } = useAppSelector(state => state.HeaderState);
	const { currentLocalId } = useAppSelector(state => state.HeadingSlice);

	const dispatch = useDispatch();
	const { isSwitcherOn, FinalSelect, ClickLocal } = useAppSelector(
		state => state.GeoState
	);

	const Geo = useAppSelector(state => state.getPostLocation);
	const LocalNews: any = useAppSelector(state => state.getPostNewsLocal);

	useEffect(() => {
		dispatch(ClearAll());
		dispatch(
			getNewsLocal({
				pages: 3,
				loc: currentLocalId,
				whiteList: WhiteList.WhiteSourses
			})
		);
	}, [isSwitcherOn, dispatch, currentLocalId, WhiteList.WhiteSourses]);

	const ArrNum = useAppSelector(state => state.getPostProperty);
	// const Geo = {
	// 	data: [
	// 		{
	// 			geoname_id: 524901,
	// 			type: 'CITY',
	// 			name: 'Москва',
	// 			population: 10381222,
	// 			latitude: 55.7522,
	// 			longitude: 37.6156,
	// 			time_zone: 'Europe/Moscow',
	// 			utc_offset: 180
	// 		},
	// 		{
	// 			geoname_id: 524894,
	// 			type: 'ADM1',
	// 			name: 'Москва',
	// 			population: 11503501,
	// 			latitude: 55.7617,
	// 			longitude: 37.6067,
	// 			time_zone: 'Europe/Moscow',
	// 			utc_offset: 180
	// 		},
	// 		{
	// 			geoname_id: 2017370,
	// 			type: 'COUNTRY',
	// 			name: 'Россия',
	// 			population: 140702000,
	// 			latitude: 60.0,
	// 			longitude: 100.0
	// 		}
	// 	]
	// };

	const [stateSit, SetstateSity] = useState('');
	useEffect(() => {
		if (isSwitcherOn === true) {
			if (Geo.data.length > 0) {
				Geo.data.map((el: any, _, allArr: any) => {
					if (el.type === 'ADM1') {
						SetstateSity(el.name);
						dispatch(SetcurrentLocalId(el.geoname_id));
						dispatch(setFinalSelect(el.name));
						setisContry(false);
					}
					if (
						allArr.length == 1 &&
						el.type === 'COUNTRY' &&
						el.geoname_id === 2017370
					) {
						setisContry(true);
					}

					// SetcurrentGeoId(el.geoname_id);
				});
			}
		} else if (isSwitcherOn === false) {
			ArrNum?.data?.locations !== undefined &&
				ArrNum?.data?.locations.map((el: any) => {
					if (el.name === FinalSelect) {
						dispatch(SetcurrentLocalId(el.id));
						// SetcurrentGeoId(el.id);
						SetstateSity(el.name);
						setisContry(false);
					}
				});
		}
	}, [Geo, ArrNum, LocalNews.Loading, isSwitcherOn, FinalSelect]);

	const { SetOpenModalWindow, setFinalSelect } = GeoState.actions;

	const ArrLoading = [1, 2, 3, 4];

	const GeoStateAll: any = useAppSelector(state => state.GeoState);

	return (
		<>
			{FinalSelect.length !== 0 && ClickLocal.length !== 0 && !isContry ? (
				<section className={style.microNews}>
					<div className={style.header}>
						{GeoStateAll.IsOpen && <ModalWindowGeo Geo={Geo} />}
						<div
							className={style.location}
							style={{ fontSize: `${15 + CurrentFontSize}px` }}
							onClick={() =>
								dispatch(SetOpenModalWindow())
							}
						>
							<svg
								width='19'
								height='19'
								viewBox='0 0 19 19'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M0.942271 7.95077L0.538553 8.11912L0.960627 8.23395L8.65616 10.3276L10.7455 18.0392L10.8599 18.4614L11.0287 18.0579L18.1384 1.05788L18.2793 0.721024L17.9423 0.861555L0.942271 7.95077ZM10.0848 9.03784L10.0626 8.95513L9.98006 8.93223L5.82173 7.7783L15.1151 3.88984L11.2031 13.2002L10.0848 9.03784Z'
									fill='black'
									stroke='#494949'
									strokeWidth='0.3'
								/>
							</svg>
							{FinalSelect}
						</div>
						<Link
							style={{ fontSize: `${14 + CurrentFontSize}px` }}
							className={style.showAll}
							href={`/region/${currentLocalId}/${TranslateFunc(stateSit)}`}
						>
							ПОКАЗАТЬ ВСЕ
						</Link>
					</div>
					<section
						className={
							newsViewBollean == false ? style.listNews : style.listNewsCards
						}
					>
						<>
							{LocalNews.Loading
								? ArrLoading.map(el => {
										return (
											<section className={style.LoadingWrapper} key={el}>
												<div className={style.Wrapper}>
													<div className={style.logoNameCont}>
														<div className={style.LoadingLogo}></div>
														<div className={style.LoadingText}></div>
													</div>
												</div>
											</section>
										);
								  })
								: LocalNews?.data?.map((el: any, ind: any) => {
										return (
											<>
												{newsViewBollean == false ? (
													<LocalhostingNews el={el} />
												) : ind < 2 ? (
													<section key={el.id} className={style.CardWrapper}>
														{el.image == null ? (
															<></>
														) : (
															<Image
																className={style.NewsPreview}
																src={el.image}
																alt='logo'
																width={280}
																height={200}
															/>
														)}

														<div className={style.NewsCardName}>
															<Image
																width={16}
																height={16}
																src={el.publisher_logo_url}
																alt='logo'
															/>
															<h4
																className={style.NewsName}
																style={{
																	fontSize: `${14 + CurrentFontSize}px`
																}}
															>
																{el.publisher_name}
															</h4>
															<ArticlesMTCounters StoryId={el.story_id} />
														</div>
														<div className={style.logoNameCont}>
															<h3
																className={style.mainCardTxt}
																style={{
																	fontSize: `${19 + CurrentFontSize}px`
																}}
															>
																<a
																	className={style.Links}
																	target={
																		window.ReactNativeWebView
																			? '_self'
																			: '_blank'
																	}
																	href={
																		el.hasOwnProperty('fulltext_properties')
																			? `/article/${el.id}/full`
																			: el.url
																	}
																>
																	{el.title}
																</a>
															</h3>
														</div>
														<NewsCardMTButtonsCard
															el={el}
															StoryId={el.story_id}
															Id={el.id}
															isStories={false}
														/>
													</section>
												) : (
													<></>
												)}
											</>
										);
								  })}
						</>
					</section>
				</section>
			) : (
				<></>
			)}
		</>
	);
};
