'use client';

import { useAppSelector } from '@/Hooks/redux';
import { LocalhostNews } from '@/Widgets/LocalNews/LocalhostNews';

import style from './page.module.sass';

import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import Options from '@/Widgets/Options/options';

// ---------------------
import { getPosts, ClearAll } from '@/Redux/saga/getPostArticle';
import { ChildComponent } from '@/Hooks/fetcher';
import { Message } from '@/lib/PostMessage';
import { useRouter } from 'next/navigation';
import { HeaderState } from '@/Redux/Slices/HeaderSlice/headerState';
import { getStoryPosts } from '@/Redux/saga/getPostStoryArticle';

export default function MainPage() {
	const { newsViewBollean } = useAppSelector(state => state.NewsViewSlice);
	const { CurrentFontSize } = useAppSelector(state => state.HeaderState);

	const WhiteList = useAppSelector(state => state.SourseSlice);

	const dispatch = useDispatch();
	const Article = useAppSelector(state => state.getPostArticle);
	const ArticleStory = useAppSelector(state => state.getPostStoryArticle);
	// observer
	const { ref, inView } = useInView({
		threshold: 1
	});

	// story
	const { SetIsStotry } = HeaderState.actions;
	const { isStory } = useAppSelector(state => state.HeaderState);

	// pagination request
	useEffect(() => {
		if (inView) {
			if (isStory) {
				const arr = ArticleStory.data.map((el: any) => el.id);
				dispatch(
					getStoryPosts({
						pages: 50,
						whiteList: WhiteList.WhiteSourses,
						filter_ids: arr
					})
				);
			}
			if (!isStory) {
				const arr = Article.data.map((el: any) => el.id);
				dispatch(
					getPosts({
						pages: 50,
						whiteList: WhiteList.WhiteSourses,
						filter_ids: arr
					})
				);
			}
		}
	}, [inView]);

	// initial request
	useEffect(() => {
		dispatch(
			getPosts({
				pages: 50,
				whiteList: WhiteList.WhiteSourses
			})
		);

		dispatch(
			getStoryPosts({
				pages: 50,
				whiteList: WhiteList.WhiteSourses
			})
		);
	}, [dispatch, WhiteList.WhiteSourses]);

	return (
		<>
			<Message
				funct={getPosts({
					pages: 50,
					whiteList: WhiteList.WhiteSourses
				})}
				rest={ClearAll}
			/>
			<Options />
			<LocalhostNews />
			<div className={style.childTitleWrapper}>
				<div className={style.cardView}>
					<button
						style={{ fontSize: `${14 + CurrentFontSize}px` }}
						className={isStory ? style.line : style.lineActive}
						onClick={() => {
							dispatch(SetIsStotry(false));
						}}
					>
						ПОПУЛЯРНОЕ
					</button>
					<button
						style={{ fontSize: `${14 + CurrentFontSize}px` }}
						className={!isStory ? style.card : style.cardActive}
						onClick={() => {
							dispatch(SetIsStotry(true));
						}}
					>
						СЮЖЕТЫ
					</button>
				</div>
			</div>
			<section
				className={
					newsViewBollean == false ? style.NewsContLine : style.NewsCont
					// ? style.NewsContLine
					// : style.NewsCont
				}
			>
				<ChildComponent
					elAll={isStory ? ArticleStory : Article}
					inputRef={ref}
					State={isStory}
				/>
			</section>
		</>
	);
}
